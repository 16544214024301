import './App.css';
import React, {useEffect, useState} from "react"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";
import whitelists from './wlfile1.json'
import MrPresident from "./contracts/Contract.json"
import Header from "./components/Header";
import OtherComponents from "./components/OtherComponents";

import slava from './img/slava.png';
import h2logo from './img/h2logo.svg';
import loader from './img/loader.svg';


const App = () => {
    // variable accepting the wallet address
    const [currentAccount, setCurrentAccount] = useState('')
    const [contract, setContract] = useState()
    const [totalSupply, setTotalSupply] = useState(0)
    const [voucher, setVoucher] = useState('')
    const [price, setPrice] = useState(0)
    const [minted, setMinted] = useState(0)
    const [count, setCount] = useState(1)
    const [isLoading,setIsLoading] = useState(false);
    const [maxCount, setMaxCount] = useState(0);
    const phaseMax = {
        one: 3333,
        two: 3333,
        three: 3334
    };
    const phasePrice = {
        one: 0.04,
        two: 0.06,
        three: 0.08
    };


    const {ethereum} = window
    const web3 = new Web3(ethereum)

    // check change account metamask
    const changeAccounts = (accounts) => {
        if (!accounts[0]) {
            setCurrentAccount('')
            setVoucher('');
        } else {
            checkVoucher(accounts[0])
            setCurrentAccount(accounts[0])
        }
    };
    if (ethereum) {
        ethereum.on('accountsChanged', changeAccounts);
    }

    // check connect metamask wallet to app
    const checkIfWalletIsConnected = async () => {
        const {ethereum} = window;
        if (!ethereum) {
            toast.error("Metamask not found, please make sure you have installed extension!");
            return;
        } else {
            console.log("We have the ethereum object", ethereum);
        }

        let chainId = await ethereum.request({method: 'eth_chainId'});
        console.log("Connected to chain " + chainId);

        const accounts = await ethereum.request({method: 'eth_accounts'});
        const networkId = await web3.eth.net.getId();
        const deployedNetwork = MrPresident.networks[networkId];
        const contract = new web3.eth.Contract(
            MrPresident.abi,
            deployedNetwork && deployedNetwork.address,
        );
        setContract(contract);
        contract.methods.totalSupply().call((error, total) => {
            setTotal(total);
        })
        if (accounts.length !== 0) {
            contract.methods.balanceOf(accounts[0]).call((error, balance) => {
                setMinted(balance)
             })
            checkVoucher(accounts[0]);
            setCurrentAccount(accounts[0])
        } else {
            console.log("No authorized account found")
        }
    }

    const checkVoucher = async (account) => {
        return;
        if (whitelists[account] !== undefined) {
            setPrice(price / 2);
            setVoucher(whitelists[account].voucher);
        }
    }

    // connect to metamask wallet
    const connectWallet = async () => {
        try {
            const {ethereum, open} = window;
            if (!ethereum) {
                toast.error("Metamask not found, please make sure you have installed extension!");
                open('https://metamask.app.link/dapp/mrpresident.io/');
            } else {
                await ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: '0x1'}],
                });

                const accounts = await ethereum.request({method: "eth_requestAccounts"});
                await checkVoucher(accounts[0]);
                setCurrentAccount(accounts[0]);
            }
        } catch (error) {
            console.log(error)
        }
    }

    // mint
    const mint = async () => {
        if (voucher) {
            contract.methods.mintNFTWithVoucher(
                    count,
                    currentAccount,
                    web3.utils.toWei(String(price), "ether"),
                    voucher
                ).send({
                from: currentAccount,
                value: web3.utils.toWei(String(price * count), "ether")
            })
                .on("transactionHash", hash => {
                    console.log('Submitted to block and received transaction hash: ' + hash);
                    setIsLoading(true);
                })
                .on("error", error => {
                    toast.error("Error!");
                    setIsLoading(false);
                    return false
                })
                .then(function (receipt) {
                    if (receipt.status) {
                        console.log('Success: ' + receipt.transactionHash)
                        toast.success("You successfuly minted "+ count + ' NFT!');
                        setMinted(minted+count);
                        setCount(1);
                        contract.methods.totalSupply().call((error, total) => {
                            setTotal(total);
                        })
                        setIsLoading(false);
                        return true
                    } else {
                        setIsLoading(false);
                        return false
                    }
                })
        } else {
            let ethToPay = price * count;
            if (count > 4) {
                ethToPay -= price;
            }
            contract.methods.mintNFT(count).send({
                from: currentAccount,
                value: web3.utils.toWei(String(ethToPay), "ether")
            })
                .on("transactionHash", hash => {
                    console.log('Submitted to block and received transaction hash: ' + hash)
                    setIsLoading(true);
                })
                .on("error", error => {
                    setIsLoading(false);
                    toast.error("Error!");
                    return false
                })
                .then(function (receipt) {
                    if (receipt.status) {
                        console.log('Success: ' + receipt.transactionHash)
                        toast.success("You successfuly minted "+ count + ' NFT!');
                        setMinted(minted+count);
                        setCount(1);
                        contract.methods.totalSupply().call((error, total) => {
                            setTotal(total);
                        })
                        setIsLoading(false);
                        return true
                    } else {
                        setIsLoading(false);
                        return false
                    }
                })
        }
    }

    const renderNotConnectedContainer = () => (
        <div className="mint__btn-connect-wrap">
            <button className="mint__btn smaller" onClick={connectWallet}>Connect to Wallet</button>
        </div>
    );

    useEffect(() => {
        checkIfWalletIsConnected();
    }, []);

    const plus = ()=> {
       if(count < maxCount) {
            setCount( prevState => prevState + 1);
        }
    }

    const minus = ()=> {
        if(count > 1){
            setCount(prevState => prevState - 1);
        }
    }

    const setTotal = (total) => {
        setTotalSupply(total);
        if (total < phaseMax.one) {
            setMaxCount(phaseMax.one - total);
            setPrice(phasePrice.one);
        } else if (total >= phaseMax.one && total < phaseMax.one + phaseMax.two) {
            setMaxCount(phaseMax.two + phaseMax.one - total);
            setPrice(phasePrice.two);
        } else {
            setMaxCount(phaseMax.three + phaseMax.two + phaseMax.one - total);
            setPrice(phasePrice.three);
        }
    }


    return (
        <div className="App">
            <Header/>
            <ToastContainer style={{marginTop: 70}}/>

            <section className="mint">
                <div className="mint__wrap">
                    <div className="mint__ze">
                        <div className="mint__ze-text">Slava Ukrainі</div>
                        <img src={slava} alt=""/>
                    </div>
                    <div className="mint__block-wrap">
                        <div className="mint__discount">
                            <p>!!!special offer: buy 4, get 1 free!!!</p>
                        </div>
                        <div className="mint__block">
                            <div className="mint__phrase">
                                <img src={h2logo} alt=""/>
                            </div>
                            <div className="mint__form">
                                {currentAccount === ""
                                    ? renderNotConnectedContainer()
                                    : (
                                        totalSupply == 10000
                                        ?
                                        <p>sold out</p>
                                        :
                                        <>
                                            <h3>{totalSupply}/10000</h3>
                                            <p>{price} ETH per 1 NFT <br/> (excluding gas fees)</p>
                                            <div className="form">
                                                <div className="mint__form-row">
                                                    <button id="btn-minus" type="button" className="mint__count-btn"
                                                            onClick={minus}
                                                    >-</button>
                                                    <input value={count} id="count" className="mint__count" readOnly
                                                        type="number" max="10000" min="1"/>
                                                    <button id="btn-plus" type="button" className="mint__count-btn"
                                                            onClick={plus}>+</button>
                                                </div>
                                                <button
                                                    disabled={isLoading}
                                                    onClick={() => mint()}
                                                    className={isLoading ? "mint__btn loader" : "mint__btn"}
                                                    type="button">
                                                    {isLoading ? <img className="mint__loader" src={loader} alt=""/> : <span>mint</span>}
                                                </button>
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                            <div className="mint__border">
                                <div className="mint__border-circle top"/>
                                <div className="mint__border-circle bot"/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <OtherComponents/>
        </div>
    );
}

export default App;


