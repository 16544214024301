import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import "swiper/components/effect-flip/effect-flip.min.css";
import SwiperCore, {  EffectFlip, Autoplay  } from "swiper";


import logo from '../img/logo.png';
import ze_batman from '../img/ze-batman.png';
import ze_getman from '../img/ze-getman.png';
import ze_tatoo from '../img/ze-tatoo.png';
import chill from '../img/chill.png';
import classic from '../img/classic.png';
import fredom from '../img/fredom.png';

SwiperCore.use([EffectFlip ,Autoplay]);

const Header = () => {

    return (
        <header className="header">
            <div className="header__left">
                <div className="header__main">
                    <div className="header__bar">
                        <div className="header__line left"/>
                        <div className="header__logo"><img src={logo} alt="Logo"/></div>
                        <div className="header__line mid"/>
                        <div className="header__phrase">
                            <div className="header__phrase-second"/>
                        </div>
                        <div className="header__line right"/>
                    </div>
                    <div className="header__president">
                        <div className="header__president-row">
                            <h1>Mr.</h1>
                            <p>Superhero, atypical world leader, <br/> and the whole planet's new crush </p>
                        </div>
                        <div className="header__president-row">
                            <h1>President</h1>
                        </div>

                    </div>
                </div>
                <div className="header__about">
                    <div className="header__about-left"><h2>About</h2></div>
                    <div className="header__about-right">
                        <p>
                            We started this project because russians came to our land in tanks and started killing our
                            citizens. In time of war, the president of Ukraine Volodymyr Zelenskyi became a
                            personification of Ukrainian freedom and bravery.
                        </p>
                        <p>
                            Zelenskyi inspires us to fight for peace. We've created this collection in his honor to help
                            raise money for Ukraine's cause.
                        </p>
                    </div>
                </div>
            </div>
            <div className="header__right swiper-container">
                <Swiper autoplay={{delay: 1200}}
                    effect="flip" flipEffect={{slideShadows: false}} grabCursor="true"
                    speed={600} slidesPerView={1} loop={true}
                >
                    <SwiperSlide>
                            <div className="header__img-block">
                                <div className="header__block rebel"/>
                                <img src={ze_tatoo} alt=""/>
                            </div>
                            <div className="header__hero-text">
                                <h2>Mr.Rebel</h2>
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="header__img-block">
                                <div className="header__block hetman"/>
                                <img src={ze_getman} alt=""/>
                            </div>
                            <div className="header__hero-text">
                                <h2>Mr.Hetman</h2>
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="header__img-block">
                                <div className="header__block batman"/>
                                <img src={ze_batman} alt=""/>
                            </div>
                            <div className="header__hero-text">
                                <h2>Mr.Batman</h2>
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="header__img-block">
                                <div className="header__block batman"/>
                                <img src={chill} alt=""/>
                            </div>
                            <div className="header__hero-text">
                                <h2>Mr.Chill</h2>
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="header__img-block">
                                <div className="header__block batman"/>
                                <img src={classic} alt=""/>
                            </div>
                            <div className="header__hero-text">
                                <h2>Mr.Classic</h2>
                            </div>
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="header__img-block">
                                <div className="header__block freedom"/>
                                <img src={fredom} alt=""/>
                            </div>
                            <div className="header__hero-text">
                                <h2>Mr.Freedom</h2>
                            </div>
                    </SwiperSlide>
                </Swiper>
                <div className="header__open-sea">
                    <a className="header__open-sea-link" target="_blank" href="https://opensea.io/collection/mr-president-ua">OPENSEA</a>
                </div>
            </div>
        </header>
    )
}

export default Header;
