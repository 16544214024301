import React from "react";
import ze1 from '../img/ze1.png';
import ze2 from '../img/ze2.png';
import ze3 from '../img/ze3.png';
import star from '../img/star.svg';
import prst from '../img/prst.svg';
import banner from '../img/banner.png';

const otherComponents = ()=> {
    return (
        <>
            <section className="roadmap">
                <div className="roadmap__content">
                    <div className="roadmap__content-header">
                        <div className="roadmap__content-square">
                            <div className="left-square"/>
                            <div className="right-square"/>
                            <div className="right-square"/>
                            <div className="left-square"/>
                            <div className="left-square"/>
                            <div className="right-square"/>
                            <div className="right-square"/>
                            <div className="left-square"/>
                            <div className="left-square"/>
                            <div className="right-square"/>
                        </div>
                        <div className="roadmap__phrase">
                            <div className="roadmap__phrase-content">
                                <div className="roadmap__phrase-flag">
                                    <div className="roadmap__phrase-flag-row blue"/>
                                    <div className="roadmap__phrase-flag-row yellow"/>
                                </div>
                                <div className="roadmap__phrase-title">
                                    <h2>roadmap</h2>
                                </div>
                                <div className="roadmap__phrase-flag">
                                    <div className="roadmap__phrase-flag-row blue"/>
                                    <div className="roadmap__phrase-flag-row yellow"/>
                                </div>
                            </div>

                        </div>
                        <div className="roadmap__content-square">
                            <div className="left-square"/>
                            <div className="right-square"/>
                            <div className="right-square"/>
                            <div className="left-square"/>
                            <div className="left-square"/>
                            <div className="right-square"/>
                            <div className="right-square"/>
                            <div className="left-square"/>
                            <div className="left-square"/>
                            <div className="right-square"/>
                        </div>
                    </div>
                    <div className="roadmap__main">
                        <div className="roadmap__main-item">
                            <p className="no-margin">
                                50% of all the project’s proceeds will go to the charity funds that help Ukrainians
                                fight for peace and freedom and provide humanitarian aid for people and animals that
                                have suffered from the war. We have a list of trustworthy charitable foundations from
                                which we want to choose the ones we’ll donate to together with our community.
                                <br/><br/>
                                    If the war continues, we will keep transferring funds to help Ukraine while 10% will
                                    be invested in the project’s future progress.
                                    <br/><br/>
                                        50% of all the royalties will also be sent to charitable initiatives and
                                        projects.
                            </p>
                        </div>
                        <div className="roadmap__main-item">
                            <p className="no-margin">
                                <span className="first-mint">First mint</span> - 3333 items; price per one - 0.04 ETH
                                <br/><br/>
                                    If the war in Ukraine is stopped at this point and peace and freedom prevail, we
                                    will:
                            </p>
                            <br/>
                                <ul>
                                    <li>raffle 5 special invitations to online/offline "Peace Party"</li>
                                    <li>start designing merchandise for our exclusive holders</li>
                                    <li>arrange 5 giveaways</li>
                                </ul>
                                <div className="roadmap__progress-item">
                                    <div className="roadmap__progress-count line25">33.3%</div>
                                    <div className="roadmap__progress-bar">
                                        <div className="roadmap__progress-line line25"/>
                                    </div>
                                </div>
                        </div>
                        <div className="roadmap__main-item">
                            <p className="no-margin">
                                <span className="second-mint">Second mint</span> items - 3333; price per one - 0.06 ETH
                                <br/><br/>
                                    If the war in Ukraine is stopped at this point and peace and freedom prevail, we
                                    will:
                                    <br/><br/>
                            </p>
                            <ul>
                                <li>present our two future projects</li>
                                <li>raffle the merchandise</li>
                                <li>raffle 10 special invitations to online/offline "Peace Party, vol.2"</li>
                                <li>start designing a new merchandise clothing line</li>
                                <li>arrange 10 giveaways</li>
                            </ul>
                            <div className="roadmap__progress-item">
                                <div className="roadmap__progress-count line50">66.6%</div>
                                <div className="roadmap__progress-bar">
                                    <div className="roadmap__progress-line line50"/>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap__main-item">
                            <p className="no-margin">
                                <span className="third-mint">Third mint</span> items - 3334; price per one - 0.08 ETH
                                <br/><br/>
                                    If the war in Ukraine is stopped at this point and peace and freedom prevail, we
                                    will:<br/>
                            </p>
                            <ul>
                                <li>raffle 10 Ukrainian handmade national "Vyshyvanka" shirts among our exclusive
                                    holders
                                </li>
                                <li>raffle new merchandise clothing line</li>
                                <li>provide exclusive access to future projects</li>
                                <li>arrange 10 giveaways</li>
                            </ul>

                            <div className="roadmap__progress-item">
                                <div className="roadmap__progress-count line100">100%</div>
                                <div className="roadmap__progress-bar">
                                    <div className="roadmap__progress-line line100"/>
                                </div>
                            </div>
                            <p className="no-margin">
                                <br/><br/>
                                    Stay tuned!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="roadmap__ze1 roadmap__ze">
                    <img src={ze1} alt=""/>
                </div>
                <div className="roadmap__ze2 roadmap__ze">
                    <img src={ze2} alt=""/>
                </div>
                <div className="roadmap__ze3 roadmap__ze">
                    <img src={ze3} alt=""/>
                </div>
            </section>
            <section className="we">
                <div className="we__banner">
                    <img src={banner} alt=""/>
                </div>
                <div className="we__btns">
                    <div className="we__btn hide">mint</div>
                    <img className="we__star hide-star" src={star} alt=""/>
                    <div className="we__btn">mint</div>
                    <img className="we__star" src={star} alt=""/>
                    <div className="we__btn">mint</div>
                    <img className="we__star" src={star} alt=""/>
                    <div className="we__btn">mint</div>
                    <img className="we__star hide-star" src={star} alt=""/>
                    <div className="we__btn hide">mint</div>
                </div>
                <div className="we__main">
                    <div className="we__left">
                        <p>We love meeting creative people, doing collaborations, and bringing some ass-kicking ideas to
                            life.
                            <br/><br/>
                                So welcome to our community!</p>
                    </div>
                    <div className="we__right">
                        <p>
                            Have any bonkers ideas you wanna share? Or maybe you want to come visit and hang out with us
                            or work together?
                            <br/><br/>
                                <span>Drop us a line!</span>
                        </p>
                        <a className="we__contact" href="mailto:alex@prstnst.agency">contact</a>
                    </div>
                </div>
            </section>

            <section id="contacts" className="footer-contacts">
                <div className="footer-contacts__content">
                    <div className="footer-contacts__btn-links">
                        <a className="Insta" target="_blank"
                           href="https://www.instagram.com/mr.president_ua/">Instagram</a>
                        <a className="Twitter" target="_blank" href="https://twitter.com/MRPRESIDENT_NFT">Twitter</a>
                        <a className="Tik" target="_blank" href="https://vm.tiktok.com/ZMLHbxnTV">Tik-Tok</a>
                        <a className="Discord" target="_blank" href="https://discord.gg/kPw9jkDcPV">Discord</a>
                    </div>
                </div>
            </section>
            <footer className="copy">
                <div className="copy__content">
                    <h2>Copyright © <a target="_blank" href="https://prstnst.agency/homepage_en/">prstnst.agency</a>
                    </h2>
                    <p>All rights reserved</p>
                    <div className="copy__img">
                        <img src={prst} alt=""/>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default otherComponents;
